import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props, IconProps } from "./types"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Star from "src/images/emoji-star.png"
import ArrowLink from "src/components/ArrowLink"
import Capterra from "src/components/Icons/CapterraBlackLogo"
import G2 from "src/components/Icons/CrowdLogo"
import GetApp from "src/components/Icons/GetAppLogo"
import { usePages } from "src/context/pages"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "Capterra":
      return <Capterra className={styles.capterra} aria-hidden="true" />
    case "G2":
      return <G2 className={styles.crowd} aria-hidden="true" />
    default:
      return <GetApp className={styles.getapp} aria-hidden="true" />
  }
}

const Reviews: FC<Props> = ({ className, subtitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/Reviews\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            title
            description
            buttonText
            list {
              text
              logo
            }
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const { getPage } = usePages()

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`content-wrapper ${styles.content}`}>
        <h2 className={styles.title}>{texts.title}</h2>
        <p className={styles.subtitle}>
          {subtitle ? subtitle : texts.description}
        </p>
        <div className={styles.reviewsContainer}>
          {texts.list.map((item: any) => (
            <div className={styles.review} key={item.text}>
              <div className={styles.stars}>
                <img src={Star} className={styles.star} />
                <img src={Star} className={styles.star} />
                <img src={Star} className={styles.star} />
                <img src={Star} className={styles.star} />
                <img src={Star} className={styles.star} />
              </div>
              <p className={styles.reviewText}>{item.text}</p>
              <Icon icon={item.logo} />
            </div>
          ))}
        </div>
        <ArrowLink
          text={texts.buttonText}
          to={getPage("reviews").url}
          className={`button-new button-new--primary ${styles.button}`}
        />
      </div>
    </div>
  )
}

export default Reviews
